import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This section will be expanded in the future. In the mean time, the `}<a parentName="p" {...{
        "href": "https://kwikswap.org/whitepaper.pdf"
      }}>{`Kwikswap whitepaper`}</a>{` has most relevant math for Kwikswap.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      